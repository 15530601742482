    import React from 'react'
    import Header from '../components/Header'
    import Termcontent from '../components/Termcontent'
    import Footer from '../components/Footer'
    
    export default function Termofuse() {
      return (
        <div>
            <Header  />
            <Termcontent />
            <Footer />
        </div>
      )
    }
    