import React from 'react'
import '../css/CoinPages.css'
export default function Portfolio() {
  return (
    <>
       <div className='col-lg-3 own_res portfolio'>
            <div className="swap_box">
                <h4 className='left-right-head'>Portfolio</h4>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <h5 className="comming_text">Coming Soon...</h5>
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </div>
       </div>
    </>
  )
}
