import React from 'react'
import '../css/header_dashboard.css'
import us from '../images/US.svg'
import bit from '../images/XTVCBTC.svg'
import eth from '../images/XTVCETH.svg'
function Dashheader() {
  return (
    <>
     <div className='own_containered'>
        <div className='header_banner'></div>
     </div>
    </>
  )
}

export default Dashheader