import React from 'react'
import Coming from '../components/Coming'
import Footer from '../components/Footer'
import Header from '../components/Header'

function Signup() {
  return (
      <>
      <Header/>
      <Coming/>
      <Footer/>
      </>
  )
}

export default Signup