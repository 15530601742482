import React from 'react'
import '../css/header_dashboard.css'
export default function DashFooter() {
  return (
    <>
       <div className='Footer_banner'></div>
       <div className='dash_footer'>
           <span>© 2022 Validefi</span>
       </div>
    </>
  )
}
