import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Aboutcontent from '../components/Aboutcontent'

const About = () => {
  return (
  <> 
   <Header /> 
   <Aboutcontent />
   <Footer />
  </>
  );
}

export default About