import React from 'react'
import Coming from '../components/Coming';
import Footer from '../components/Footer';
import Header from '../components/Header';


export default function Whitepaper() {
  return (
    <>
     <Header />
     <Coming />
     <Footer />
    </>
  )
}
