export const chartDays = [
    {
      label: "24H",
      value: 1,
    },
    {
      label: "30D",
      value: 30,
    },
    {
      label: "3M",
      value: 90,
    },
    {
      label: "1Y",
      value: 365,
    },
  ];
  